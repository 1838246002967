import { DateAdapter } from 'angular-calendar';
import isoWeek from 'dayjs/plugin/isoWeek';
import minMax from 'dayjs/plugin/minMax';
import timezone from 'dayjs/plugin/timezone';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { zonedDayJs } from 'src/app/office/shared/service/locale.service';
// Extend dayjs with required plugins
zonedDayJs.extend(weekday);
zonedDayJs.extend(isoWeek);
zonedDayJs.extend(weekOfYear);
zonedDayJs.extend(minMax);
zonedDayJs.extend(timezone);

export function dayjsAdapterFactory(): DateAdapter {
  function startOfWeek(date: Date | number): Date {
    return zonedDayJs(date).tz().startOf('week').toDate();
  }

  function endOfWeek(date: Date | number): Date {
    return zonedDayJs(date).tz().endOf('week').toDate();
  }

  function getDay(date: Date | number): number {
    return zonedDayJs(date).tz().day();
  }

  return {
    addDays: (date: Date | number, amount: number) =>
      zonedDayJs(date).tz().add(amount, 'day').toDate(),
    addHours: (date: Date | number, amount: number) =>
      zonedDayJs(date).tz().add(amount, 'hour').toDate(),
    addMinutes: (date: Date | number, amount: number) =>
      zonedDayJs(date).tz().add(amount, 'minute').toDate(),
    addSeconds: (date: Date | number, amount: number) =>
      zonedDayJs(date).tz().add(amount, 'second').toDate(),
    differenceInDays: (dateLeft: Date | number, dateRight: Date | number) =>
      zonedDayJs(dateLeft).tz().diff(zonedDayJs(dateRight), 'day'),
    differenceInMinutes: (dateLeft: Date | number, dateRight: Date | number) =>
      zonedDayJs(dateLeft).tz().diff(zonedDayJs(dateRight), 'minute'),
    differenceInSeconds: (dateLeft: Date | number, dateRight: Date | number) =>
      zonedDayJs(dateLeft).tz().diff(zonedDayJs(dateRight), 'second'),
    endOfDay: (date: Date | number) =>
      zonedDayJs(date).tz().endOf('day').toDate(),
    endOfMonth: (date: Date | number) =>
      zonedDayJs(date).tz().endOf('month').toDate(),
    endOfWeek,
    getDay,
    getMonth: (date: Date | number) => zonedDayJs(date).tz().month(),
    isSameDay: (dateLeft: Date | number, dateRight: Date | number) =>
      zonedDayJs(dateLeft).tz().isSame(zonedDayJs(dateRight).tz(), 'day'),
    isSameMonth: (dateLeft: Date | number, dateRight: Date | number) =>
      zonedDayJs(dateLeft).tz().isSame(zonedDayJs(dateRight).tz(), 'month'),
    isSameSecond: (dateLeft: Date | number, dateRight: Date | number) =>
      zonedDayJs(dateLeft).tz().isSame(zonedDayJs(dateRight).tz(), 'second'),
    max: (dates: (Date | number)[]) => {
      const daysArray = dates.map((date) => zonedDayJs(date));
      return zonedDayJs.max(daysArray).toDate();
    },
    setHours: (date: Date | number, hours: number) =>
      zonedDayJs(date).tz().set('hour', hours).toDate(),
    setMinutes: (date: Date | number, minutes: number) =>
      zonedDayJs(date).tz().set('minute', minutes).toDate(),
    startOfDay: (date: Date | number) =>
      zonedDayJs(date).tz().startOf('day').toDate(),
    startOfMinute: (date: Date | number) =>
      zonedDayJs(date).tz().startOf('minute').toDate(),
    startOfMonth: (date: Date | number) =>
      zonedDayJs(date).tz().startOf('month').toDate(),
    startOfWeek,
    getHours: (date: Date | number) => {
      return zonedDayJs(date).tz().get('hour');
    },
    getMinutes: (date: Date | number) => zonedDayJs(date).tz().get('minute'),
    getTimezoneOffset: (date: Date | number) =>
      zonedDayJs(date).tz().utcOffset() * -1,
    addWeeks: (date: Date | number, amount: number) =>
      zonedDayJs(date).tz().add(amount, 'week').toDate(),
    addMonths: (date: Date | number, amount: number) =>
      zonedDayJs(date).tz().add(amount, 'month').toDate(),
    subDays: (date: Date | number, amount: number) =>
      zonedDayJs(date).tz().subtract(amount, 'day').toDate(),
    subWeeks: (date: Date | number, amount: number) =>
      zonedDayJs(date).tz().subtract(amount, 'week').toDate(),
    subMonths: (date: Date | number, amount: number) =>
      zonedDayJs(date).tz().subtract(amount, 'month').toDate(),
    getISOWeek: (date: Date | number) => zonedDayJs(date).tz().week(), // Using week() instead of isoWeek()
    setDate: (date: Date | number, dayOfMonth: number) =>
      zonedDayJs(date).tz().date(dayOfMonth).toDate(),
    setMonth: (date: Date | number, month: number) =>
      zonedDayJs(date).tz().month(month).toDate(),
    setYear: (date: Date | number, year: number) =>
      zonedDayJs(date).tz().year(year).toDate(),
    getDate: (date: Date | number) => zonedDayJs(date).tz().date(),
    getYear: (date: Date | number) => zonedDayJs(date).tz().year(),
  };
}
