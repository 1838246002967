import { Injectable } from '@angular/core';
import {
  CalendarDateFormatterInterface,
  DateAdapter,
  DateFormatterParams,
  getWeekViewPeriod,
} from 'angular-calendar';
import dayjs from 'dayjs';

/**
 * This is modeled after the Moment adapter,, but using dayjs instead as we do everywhere else.
 * ```
 */
@Injectable()
export class CalendarDayjsDateFormatter
  implements CalendarDateFormatterInterface
{
  /**
   * @hidden
   */
  constructor(protected dateAdapter: DateAdapter) {}

  /**
   * The month view header week day labels
   */
  public monthViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return dayjs(date).tz().format('dddd');
  }

  /**
   * The month view cell day number
   */
  public monthViewDayNumber({ date, locale }: DateFormatterParams): string {
    return dayjs(date).tz().format('D');
  }

  /**
   * The month view title
   */
  public monthViewTitle({ date, locale }: DateFormatterParams): string {
    return dayjs(date).tz().format('MMMM YYYY');
  }

  /**
   * The week view header week day labels
   */
  public weekViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return dayjs(date).tz().format('dddd');
  }

  /**
   * The week view sub header day and month labels
   */
  public weekViewColumnSubHeader({
    date,
    locale,
  }: DateFormatterParams): string {
    return dayjs(date).tz().format('MMM D');
  }

  /**
   * The week view title
   */
  public weekViewTitle({
    date,
    locale,
    weekStartsOn,
    excludeDays,
    daysInWeek,
  }: DateFormatterParams): string {
    const { viewStart, viewEnd } = getWeekViewPeriod(
      this.dateAdapter,
      date,
      weekStartsOn,
      excludeDays,
      daysInWeek
    );
    const format = (dateToFormat: Date, showYear: boolean) =>
      dayjs(dateToFormat)
        .tz()
        .format('MMM D' + (showYear ? ', YYYY' : ''));
    return `${format(
      viewStart,
      viewStart.getUTCFullYear() !== viewEnd.getUTCFullYear()
    )} - ${format(viewEnd, true)}`;
  }

  /**
   * The time formatting down the left hand side of the week view
   */
  public weekViewHour({ date, locale }: DateFormatterParams): string {
    return dayjs(date).tz().format('ha');
  }

  /**
   * The time formatting down the left hand side of the day view
   */
  public dayViewHour({ date, locale }: DateFormatterParams): string {
    return dayjs(date).tz().format('ha');
  }

  /**
   * The day view title
   */
  public dayViewTitle({ date, locale }: DateFormatterParams): string {
    return dayjs(date).tz().format('dddd, LL'); // dddd = Thursday
  } // LL = locale-dependent Month Day, Year
}
