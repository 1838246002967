import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import dayjs from 'dayjs';

// Required plugins
import { zonedDayJs } from '../office/shared/service/locale.service';

declare const ngDevMode: object | null;

export interface DayJsDateAdapterOptions {
  /**
   * Turns the use of utc dates on or off.
   * Changing this will change how Angular Material DatePicker outputs dates.
   * {@default false}
   */
  useUtc?: boolean;
}

/** InjectionToken for dayjs date adapter to configure options. */
export const MAT_DAYJS_DATE_ADAPTER_OPTIONS =
  new InjectionToken<DayJsDateAdapterOptions>(
    'MAT_DAYJS_DATE_ADAPTER_OPTIONS',
    {
      providedIn: 'root',
      factory: () => ({ useUtc: false }),
    }
  );

/** Creates an array and fills it with values. */
function range<T>(length: number, valueFunction: (index: number) => T): T[] {
  const valuesArray = Array(length);
  for (let i = 0; i < length; i++) {
    valuesArray[i] = valueFunction(i);
  }
  return valuesArray;
}
export type DateStyles = 'long' | 'short' | 'narrow';

@Injectable()
export class DayjsDateAdapter extends DateAdapter<dayjs.Dayjs> {
  constructor(
    @Optional()
    @Inject(MAT_DAYJS_DATE_ADAPTER_OPTIONS)
    private readonly options?: DayJsDateAdapterOptions
  ) {
    super();
  }

  getYear(date: dayjs.Dayjs): number {
    return zonedDayJs(date).tz().year();
  }

  getMonth(date: dayjs.Dayjs): number {
    return zonedDayJs(date).tz().month();
  }

  getDate(date: dayjs.Dayjs): number {
    return zonedDayJs(date).tz().date();
  }

  getDayOfWeek(date: dayjs.Dayjs): number {
    return zonedDayJs(date).tz().day();
  }

  getMonthNames(style: DateStyles): string[] {
    return style === 'long'
      ? zonedDayJs.localeData().months()
      : zonedDayJs.localeData().monthsShort();
  }

  getDateNames(): string[] {
    const dtf =
      typeof Intl !== 'undefined'
        ? new Intl.DateTimeFormat(this.locale, {
            day: 'numeric',
            timeZone: 'utc',
          })
        : null;

    return range(31, (i) => {
      if (dtf) {
        const date = new Date();
        date.setUTCFullYear(2017, 0, i + 1);
        date.setUTCHours(0, 0, 0, 0);
        return dtf.format(date).replace(/[\u200e\u200f]/g, '');
      }
      return String(i + 1);
    });
  }

  getDayOfWeekNames(style: DateStyles): string[] {
    switch (style) {
      case 'long':
        return zonedDayJs.localeData().weekdays();
      case 'short':
        return zonedDayJs.localeData().weekdaysShort();
      case 'narrow':
        return zonedDayJs.localeData().weekdaysMin();
    }
  }

  getYearName(date: dayjs.Dayjs): string {
    return zonedDayJs(date).tz().format('YYYY');
  }

  getFirstDayOfWeek(): number {
    return zonedDayJs.localeData().firstDayOfWeek();
  }

  getNumDaysInMonth(date: dayjs.Dayjs): number {
    return this.dayJs(date).daysInMonth();
  }

  getHours(date: dayjs.Dayjs): number {
    return this.dayJs(date).hour();
  }

  getHour(date: dayjs.Dayjs): number {
    return this.dayJs(date).hour();
  }

  setHours(date: dayjs.Dayjs, value: number): dayjs.Dayjs {
    return this.dayJs(date).hour(value);
  }

  setHour(date: dayjs.Dayjs, value: number): dayjs.Dayjs {
    return this.dayJs(date).hour(value);
  }

  addHours(date: dayjs.Dayjs, amount: number): dayjs.Dayjs {
    return this.dayJs(date).add(amount, 'hour');
  }

  // Minutes
  getMinutes(date: dayjs.Dayjs): number {
    return this.dayJs(date).minute();
  }

  getMinute(date: dayjs.Dayjs): number {
    return this.dayJs(date).minute();
  }

  setMinutes(date: dayjs.Dayjs, value: number): dayjs.Dayjs {
    return this.dayJs(date).minute(value);
  }

  setMinute(date: dayjs.Dayjs, value: number): dayjs.Dayjs {
    return this.dayJs(date).minute(value);
  }

  addMinutes(date: dayjs.Dayjs, amount: number): dayjs.Dayjs {
    return this.dayJs(date).add(amount, 'minute');
  }

  // Seconds
  getSeconds(date: dayjs.Dayjs): number {
    return this.dayJs(date).second();
  }

  getSecond(date: dayjs.Dayjs): number {
    return this.dayJs(date).second();
  }

  setSeconds(date: dayjs.Dayjs, value: number): dayjs.Dayjs {
    return this.dayJs(date).second(value);
  }

  setSecond(date: dayjs.Dayjs, value: number): dayjs.Dayjs {
    return this.dayJs(date).second(value);
  }

  addSeconds(date: dayjs.Dayjs, amount: number): dayjs.Dayjs {
    return this.dayJs(date).add(amount, 'second');
  }

  // Milliseconds
  getMilliseconds(date: dayjs.Dayjs): number {
    return this.dayJs(date).millisecond();
  }

  getMillisecond(date: dayjs.Dayjs): number {
    return this.dayJs(date).millisecond();
  }

  setMilliseconds(date: dayjs.Dayjs, value: number): dayjs.Dayjs {
    return this.dayJs(date).millisecond(value);
  }

  setMillisecond(date: dayjs.Dayjs, value: number): dayjs.Dayjs {
    return this.dayJs(date).millisecond(value);
  }

  addMilliseconds(date: dayjs.Dayjs, amount: number): dayjs.Dayjs {
    return this.dayJs(date).add(amount, 'millisecond');
  }

  setTime(date: dayjs.Dayjs, hours: number, minutes: number): dayjs.Dayjs {
    return this.dayJs(date).hour(hours).minute(minutes);
  }

  override parseTime(
    value: any,
    parseFormat: string | string[]
  ): dayjs.Dayjs | null {
    if (!value) {
      return null;
    }

    // Parse time string in HH:mm format
    const [hours, minutes] = value.split(':').map(Number);

    if (isNaN(hours) || isNaN(minutes)) {
      return null;
    }

    return this.dayJs().hour(hours).minute(minutes).second(0).millisecond(0);
  }

  compareTime(first: dayjs.Dayjs, second: dayjs.Dayjs): number {
    if (!first || !second) return 0;

    const firstHour = this.getHours(first);
    const secondHour = this.getHours(second);

    if (firstHour !== secondHour) {
      return firstHour - secondHour;
    }

    const firstMinute = this.getMinutes(first);
    const secondMinute = this.getMinutes(second);

    return firstMinute - secondMinute;
  }

  createDateTime(
    year: number,
    month: number,
    date: number,
    hours: number,
    minutes: number
  ): dayjs.Dayjs {
    if (month < 0 || month > 11) {
      throw Error(
        `Invalid month index "${month}". Month index has to be between 0 and 11.`
      );
    }
    if (date < 1) {
      throw Error(`Invalid date "${date}". Date has to be greater than 0.`);
    }
    if (hours < 0 || hours > 23) {
      throw Error(
        `Invalid hours "${hours}". Hours has to be between 0 and 23.`
      );
    }
    if (minutes < 0 || minutes > 59) {
      throw Error(
        `Invalid minutes "${minutes}". Minutes has to be between 0 and 59.`
      );
    }

    const result = this.dayJs()
      .year(year)
      .month(month)
      .date(date)
      .hour(hours)
      .minute(minutes)
      .second(0)
      .millisecond(0);

    if (!result.isValid()) {
      throw Error(`Invalid date time`);
    }

    return result;
  }

  clone(date: dayjs.Dayjs): dayjs.Dayjs {
    return date.clone();
  }

  createDate(year: number, month: number, date: number): dayjs.Dayjs {
    // Validate input parameters
    if (typeof ngDevMode === 'undefined' || ngDevMode) {
      if (month < 0 || month > 11) {
        throw Error(
          `Invalid month index "${month}". Month index has to be between 0 and 11.`
        );
      }
      if (date < 1) {
        throw Error(`Invalid date "${date}". Date has to be greater than 0.`);
      }
      if (date > 31) {
        throw Error(`Invalid date "${date}". Date has to be less than 32.`);
      }
    }

    const result = zonedDayJs([year, month, date], undefined, undefined, true)
      .tz()
      .startOf('day');

    if (!result.isValid() && (typeof ngDevMode === 'undefined' || ngDevMode)) {
      throw Error(`Invalid date "${date}" for month with index "${month}".`);
    }

    return result;
  }

  today(): dayjs.Dayjs {
    return this.dayJs();
  }

  parse(value: any, parseFormat?: string | string[]): dayjs.Dayjs | null {
    if (value && typeof value === 'string') {
      return this.dayJs(value, parseFormat, this.locale, true);
    }
    if (value) {
      return this.dayJs(value, undefined, this.locale, true);
    }
    return null;
  }

  format(date: dayjs.Dayjs, displayFormat: string): string {
    if (!this.isValid(date)) {
      throw Error('DayjsDateAdapter: Cannot format invalid date.');
    }
    return date.format(displayFormat);
  }

  addCalendarYears(date: dayjs.Dayjs, years: number): dayjs.Dayjs {
    return zonedDayJs(date).add(years, 'year');
  }

  addCalendarMonths(date: dayjs.Dayjs, months: number): dayjs.Dayjs {
    return zonedDayJs(date).add(months, 'month');
  }

  addCalendarDays(date: dayjs.Dayjs, days: number): dayjs.Dayjs {
    return zonedDayJs(date).add(days, 'day');
  }

  toIso8601(date: dayjs.Dayjs): string {
    return zonedDayJs(date).tz().toISOString();
  }

  override deserialize(value: any): dayjs.Dayjs | null {
    let date;
    if (value instanceof Date) {
      date = this.dayJs(value);
    } else if (this.isDateInstance(value)) {
      return this.clone(value);
    }
    if (typeof value === 'string') {
      if (!value) {
        return null;
      }
      // Handle ISO strings with T separator differently
      date = value.includes('T')
        ? this.dayJs(value)
        : this.dayJs(value, undefined, undefined, true);
    }
    if (date && this.isValid(date)) {
      return this.dayJs(date);
    }
    return super.deserialize(value);
  }

  isDateInstance(obj: any): boolean {
    return dayjs.isDayjs(obj);
  }

  isValid(date: dayjs.Dayjs): boolean {
    return this.dayJs(date).isValid();
  }

  invalid(): dayjs.Dayjs {
    return this.dayJs(null);
  }

  private dayJs(
    input?: any,
    format?: string | string[],
    locale?: string,
    keepLocalTime?: boolean
  ): dayjs.Dayjs {
    const { useUtc }: DayJsDateAdapterOptions = this.options || {};
    const result =
      input instanceof Date || typeof input === 'number' || !format
        ? zonedDayJs(input, undefined, locale)
        : zonedDayJs(input, format, locale);

    return useUtc ? result.utc(keepLocalTime) : result.tz();
  }
}
