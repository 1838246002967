import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { SharedService } from '../../../shared/services/shared.service';
import { OfficeService } from '../../../office/shared/service/office.service';
import { AmplitudeAnalyticsService } from '../../../shared/services/analytics/amplitude-analytics.service';
import { ClientsService } from '../../my-clients/service/clients.service';
import {
  ChannelService,
  ChatClientService,
  CustomTemplatesService,
  DefaultStreamChatGenerics,
  getChannelDisplayText,
  StreamChatModule,
  StreamI18nService,
} from 'stream-chat-angular';
import { AlertService } from '../../../shared/components/alert/service/alert.service';
import { MessagingService } from '../service/messaging.service';
import {
  AfterViewInit,
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { BookingFormContainerComponent } from '../../booking/booking-form-container/booking-form-container.component';
import { environment } from '../../../../environments/environment';
import { PaymentType } from 'src/app/entities/PaymentType';
import { User } from '../../../entities/user.model';
import { SidenavComponent } from '../../../frame/sidenav/sidenav.component';
import { ConversationHeaderComponent } from '../conversation-header/conversation-header.component';
import {
  AsyncPipe,
  NgClass,
  NgForOf,
  NgIf,
  NgSwitch,
  NgSwitchCase,
} from '@angular/common';
import { StreamMessageInputComponent } from '../stream-message-input/stream-message-input.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { PaymentModalComponent } from '../../../shared/components/payment-modal/payment-modal.component';
import { Animation } from '../../../shared/animations/fade-animation';
import { Attachment, Channel } from 'stream-chat';
import { Conversation } from '../../../entities/conversation.model';
import { MonitoringService } from '../../../shared/services/monitoring/monitoring.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LeanConversationUser } from '../../../entities/lean-conversation-user.model';
import { BookingAttachmentComponent } from '../booking-attachment/booking-attachment.component';
import { InvoiceAttachmentComponent } from '../invoice-attachment/invoice-attachment.component';
import { Appointment } from '../../../entities/appointment.model';
import { EapNotAllowedBannerComponent } from '../eap-not-allowed-banner/eap-not-allowed-banner.component';
import { getOtherMemberIfOneToOneChannel } from '../../../shared/helpers/stream_chat_helper';
import { filter, map } from 'rxjs/operators';
import { StreamConversationLoadingComponent } from '../stream-conversation-loading/stream-conversation-loading.component';
import {
  CustomAttachmentListContext,
  DefaultUserType,
} from 'stream-chat-angular/lib/types';
import { LogAttachmentComponent } from '../log-attachment/log-attachment.component';
import { EapReminderBannerComponent } from '../eap-reminder-banner/eap-reminder-banner.component';

@Component({
  selector: 'app-stream-conversation',
  templateUrl: './stream-conversation.component.html',
  styleUrls: ['./stream-conversation.component.scss'],
  animations: [Animation.fadeAnimation],
  imports: [
    SidenavComponent,
    StreamChatModule,
    ConversationHeaderComponent,
    NgIf,
    AsyncPipe,
    StreamMessageInputComponent,
    NgClass,
    TranslateModule,
    FormsModule,
    BookingFormContainerComponent,
    PaymentModalComponent,
    NgForOf,
    BookingAttachmentComponent,
    InvoiceAttachmentComponent,
    EapNotAllowedBannerComponent,
    StreamConversationLoadingComponent,
    LogAttachmentComponent,
    NgSwitch,
    NgSwitchCase,
    EapReminderBannerComponent,
  ],
})
export class StreamConversationComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  protected readonly PaymentType = PaymentType;
  channel: Channel<DefaultStreamChatGenerics> | undefined;

  @ViewChild('customAttachments')
  customAttachmentsTemplate!: TemplateRef<CustomAttachmentListContext>;

  @ViewChild('messageActionsBoxTemplate', { static: true })
  customMessageActionsBoxTemplate!: TemplateRef<any>;

  public conversation: Conversation;
  public currentUser: User;
  public otherUser: LeanConversationUser;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private sharedService: SharedService,
    private officeService: OfficeService,
    private analytics: AmplitudeAnalyticsService,
    private clientService: ClientsService,
    private chatClientService: ChatClientService,
    private channelService: ChannelService,
    private alertService: AlertService,
    private messagingService: MessagingService,
    private activatedRoute: ActivatedRoute,
    private customTemplatesService: CustomTemplatesService,
    private ngZone: NgZone,
    private router: Router,
    private streamI18nService: StreamI18nService
  ) {
    this.streamI18nService.setTranslation();
  }

  @ViewChild('acceptBookingButton', { static: false })
  acceptBookingButton: ElementRef;

  @ViewChild('bookingModal', { static: false })
  bookingModal: BookingFormContainerComponent;

  public env = environment;
  public currentTimezone: string =
    Intl.DateTimeFormat().resolvedOptions().timeZone;
  public showTimezone: boolean = false;
  public isSupportChat: boolean = false;
  public wantsToReportUser: boolean = false;
  public isReportingUser: boolean = false;
  public otherUserIsOnline: boolean = false;
  public otherUserCanReceiveMessages: boolean = false;
  public otherUserIsDeactivated: boolean = false;
  public otherUserIsUnderReview: boolean;
  public channelIsHidden: boolean = false;

  private isOnlineSubscription: Subscription | undefined;
  public report: any = {
    includeMessages: true,
    description: '',
  };

  isInitializing$: Observable<boolean>;
  isActiveChannel$: Observable<boolean>;
  isError$: Observable<boolean>;

  private conversationErrorSubject = new BehaviorSubject<boolean>(false);
  public conversationError$ = this.conversationErrorSubject.asObservable();

  ngOnInit() {
    // Subscribe to current user
    this.subscriptions.add(
      this.sharedService.currentUser.subscribe((user) => {
        const isFirstLoad = !this.currentUser;
        this.currentUser = user;
        if (this.currentUser) {
          this.showTimezone =
            this.currentTimezone !== this.currentUser.profile.timezone;
        }
        if (isFirstLoad) {
          this.loadConversation();
        }
      })
    );

    this.isInitializing$ = combineLatest([
      this.channelService.channelQueryState$,
      this.channelService.activeChannel$,
    ]).pipe(
      map(([state, activeChannel]) => {
        return !activeChannel && state?.state === 'in-progress';
      })
    );

    this.isActiveChannel$ = this.channelService.activeChannel$.pipe(
      map((c) => !!c && c.id === this.channel?.id)
    );

    this.isError$ = combineLatest([
      this.channelService.channelQueryState$,
      this.channelService.activeChannel$,
      this.conversationError$,
    ]).pipe(
      map(([state, activeChannel, conversationError]) => {
        return (
          (!activeChannel && state?.state === 'error') || conversationError
        );
      })
    );
  }

  loadConversation() {
    this.messagingService
      .getConversation(this.activatedRoute.snapshot.params.id)
      .subscribe(
        (res) => {
          if (!res || !res['data'] || !res['data']['attributes']) {
            MonitoringService.captureMessage('Conversation not found: ', {
              extra: {
                response: res,
              },
            });
            this.conversationErrorSubject.next(true); // Emit error state
            return;
          }
          this.conversation = res['data']['attributes'];
          this.otherUser =
            this.conversation.sender.id === this.currentUser.id
              ? this.conversation.recipient
              : this.conversation.sender;

          this.isSupportChat = this.otherUser.id === environment.supportUserId;
          // load channel
          this.channel = this.chatClientService.chatClient.getChannelByMembers(
            'messaging',
            {
              members: [
                this.currentUser.external_id,
                this.otherUser.external_id,
              ],
            }
          );

          this.channel.watch({ presence: true }).then(() => {
            this.channelService.setAsActiveChannel(this.channel);
            const otherMember = getOtherMemberIfOneToOneChannel(
              this.currentUser,
              this.channel
            );
            this.channelIsHidden =
              (this.channel?.data?.hidden as boolean) || false;

            this.otherUserIsDeactivated =
              !!otherMember.deactivated_at || this.otherUser.blocked;
            this.otherUserIsUnderReview =
              !this.otherUserIsDeactivated && !!this.otherUser.flagged;
            this.otherUserCanReceiveMessages =
              !this.otherUserIsDeactivated && !this.otherUserIsUnderReview;
            this.listenForOnlineChanges(otherMember);
          });
        },
        () => {
          this.conversationErrorSubject.next(true); // Emit error state on failure
        }
      );
  }

  ngAfterViewInit(): void {
    this.customTemplatesService.customAttachmentListTemplate$.next(
      this.customAttachmentsTemplate
    );

    this.customTemplatesService.messageActionsBoxTemplate$.next(
      this.customMessageActionsBoxTemplate
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe(); // Unsubscribe from all subscriptions
  }

  async onHideChannel() {
    const previousState = this.channelIsHidden;
    this.channelIsHidden = true;
    try {
      await this.channel.hide();
      this.alertService.success('messaging.conversation-hidden', null, true);
      this.router.navigate(['/home/messaging/']);
    } catch (error) {
      console.error('Error hiding channel:', error);
      this.channelIsHidden = previousState; // Rollback state
      this.alertService.error(
        'common.generic_error.message',
        'common.generic_error.title'
      );
    }
  }

  async onUnhideChannel() {
    const previousState = this.channelIsHidden;
    this.channelIsHidden = false;
    try {
      await this.channel.show();
      this.alertService.success('messaging.conversation-un-hidden', null, true);
    } catch (error) {
      console.error('Error showing channel:', error);
      this.channelIsHidden = previousState; // Rollback state
      this.alertService.error(
        'common.generic_error.message',
        'common.generic_error.title'
      );
    }
  }

  private async listenForOnlineChanges(otherMember: DefaultUserType) {
    // tslint:disable-next-line:max-line-length
    // Swiped most of this from here https://github.com/GetStream/stream-chat-angular/blob/8710aa79b1ffe378cf01e0bc46d94aa05e492bd0/projects/stream-chat-angular/src/lib/avatar/avatar.component.ts#L157
    // We could just use their components in the future dashboard
    if (otherMember) {
      this.otherUserIsOnline = !!otherMember.online || false;
      this.isOnlineSubscription = this.chatClientService.events$
        .pipe(filter((e) => e.eventType === 'user.presence.changed'))
        .subscribe((event) => {
          if (event.event.user?.id === otherMember.id) {
            this.ngZone.run(() => {
              this.otherUserIsOnline = event.event.user?.online || false;
            });
          }
        });
    } else {
      this.otherUserIsOnline = false;
      this.isOnlineSubscription?.unsubscribe();
    }
  }

  get title() {
    if (!this.channel) {
      return '';
    }
    return getChannelDisplayText(
      this.channel,
      this.chatClientService.chatClient.user!
    );
  }

  reportUser() {
    if (this.isReportingUser) {
      return;
    }
    this.isReportingUser = true;

    this.clientService
      .reportClient(
        this.otherUser,
        this.report.description,
        this.latestMessages(10)
      )
      .subscribe((response) => {
        if (response.status === 'ok') {
          this.alertService.success('User reported');
          if (this.otherUser) {
            this.otherUser.flagged = true;
          }
        }
        this.isReportingUser = false;
        this.report.description = '';
        this.wantsToReportUser = false;
      });
  }

  latestMessages(count: number) {
    let min =
      this.channelService.activeChannel.state.latestMessages.length - count;
    if (min < 0) {
      min = 0;
    }
    const max =
      this.channelService.activeChannel.state.latestMessages.length + 1;
    const messageSlice =
      this.channelService.activeChannel.state.latestMessages.slice(min, max);

    // sort by pk desc
    messageSlice.sort(
      (a, b) => b.created_at.getTime() - a.created_at.getTime()
    );

    return messageSlice;
  }

  onCreateBooking() {
    this.analytics.trackScheduleAppointmentStarted({
      source_page: 'messages_page',
    });

    if (!this.otherUser) {
      return;
    }

    if (this.currentUser.type === 'Client') {
      MonitoringService.captureMessage(
        'Client trying to book appointment with client'
      );
      return;
    }

    if (!this.bookingModal) {
      MonitoringService.captureMessage('Booking modal not found');
      return;
    }

    this.bookingModal.showForNewClientBooking(this.otherUser);
  }

  onCreateInvoice() {
    this.officeService.toggleCreateInvoiceModal(true);
  }

  public editAppointment(appointment: Appointment) {
    // stream attachments don't have the client, here we can assume it's the other user
    // in the new dashboard, the editing modal should be able to load the appointment from the server
    const updatedAppointment = {
      ...appointment,
      client: this.otherUser,
    };
    this.bookingModal.showForEditingAppointment(updatedAppointment);
  }

  isInvoice(attachment: Attachment<DefaultStreamChatGenerics>): boolean {
    return attachment.type === 'invoice';
  }

  isAppointment(attachment: Attachment<DefaultStreamChatGenerics>): boolean {
    return attachment.type === 'appointment';
  }

  isOnlineSessionLog(
    attachment: Attachment<DefaultStreamChatGenerics>
  ): boolean {
    return attachment.type === 'online_session_log';
  }

  canUseVideoCallForAppointment(attachment: any): boolean {
    return this.currentUser &&
      attachment &&
      attachment.profile_id.toString() ===
        this.currentUser.profile.id.toString()
      ? this.currentUser.abilities.can_start_video_call
      : this.otherUser.can_start_video_calls;
  }
}
