import { NgFor, NgIf } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from '@danielmoncada/angular-datetime-picker';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskDirective } from 'ngx-mask';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Appointment } from '../../../entities/appointment.model';
import { CalendarService } from '../../calendar/service/calendar.service';
import { LocalisedDatePipe } from '../booking-form-container/localised-date.pipe';
import { zonedDayJs } from '../../../office/shared/service/locale.service';
import { DateHelpers } from '../../../shared/helpers/date_helpers';

@Component({
  selector: 'app-booking-form-select-date',
  templateUrl: './booking-form-select-date.component.html',
  styleUrls: ['./booking-form-select-date.component.scss'],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxMaskDirective,
    NgIf,
    NgFor,
    TranslateModule,
    LocalisedDatePipe,
  ],
})
export class BookingFormSelectDateComponent implements OnInit, OnDestroy {
  @Input() requestBookingForm: FormGroup;
  @Input() defaultAppointmentDuration: number;
  @Input() onNext: () => void;
  @Input() onCancel: () => void;

  public isLoadingOtherAppointments = false;
  public otherAppointments: Array<Appointment>;
  public minDate = zonedDayJs().add(1, 'hour').startOf('hour').tz().toDate();
  public displayDate: Date;
  public duration: number;

  private subscription: Subscription;

  constructor(
    private calendarService: CalendarService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    const formDate = this.requestBookingForm.get('appointmentDate').value;

    // Get the offset in minutes between the desired timezone and UTC
    this.minDate = DateHelpers.dayJsToNativeDatePreserveTz(
      zonedDayJs().add(1, 'hour').startOf('hour').tz()
    );

    this.displayDate = formDate
      ? DateHelpers.dayJsToNativeDatePreserveTz(formDate)
      : this.minDate;

    // Initialize duration
    this.duration =
      this.requestBookingForm.get('duration').value ||
      this.defaultAppointmentDuration;

    // Subscribe to form date changes for appointment reloading
    this.subscription = this.requestBookingForm
      .get('appointmentDate')
      .valueChanges.pipe(
        map((date: Date) => zonedDayJs(date).tz().format('YYYY-MM-DD')),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.reloadAppointmentList();
      });

    // Load initial appointments
    this.reloadAppointmentList();
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  onDateChange(value: any): void {
    if (value) {
      // we keep local time, because for some reason the dayjs adapter does not put
      // the correct timezone so we have to shift the timezone, but keep the
      // displayed time
      this.requestBookingForm.patchValue({
        appointmentDate: zonedDayJs(value).tz(undefined, true),
      });
    }
  }

  onDurationChange(value: number): void {
    this.requestBookingForm.patchValue({
      duration: value,
    });
  }

  public reloadAppointmentList(): void {
    if (!this.requestBookingForm.get('appointmentDate').value) {
      return;
    }

    this.isLoadingOtherAppointments = true;
    this.cdr.detectChanges();

    this.calendarService
      .getAppointmentsForDay(
        this.requestBookingForm.get('appointmentDate').value
      )
      .subscribe((response) => {
        this.otherAppointments = response;
        this.isLoadingOtherAppointments = false;
        this.cdr.detectChanges();
      });
  }

  next() {
    this.onNext();
  }

  cancel() {
    this.onCancel();
  }
}
